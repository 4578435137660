
					@import './src/sass/variables';
					@import './src/sass/mixins';
				









































































































































































@include breakpoint-min('medium') {
	.columns {
		display: flex;
		gap: $default_padding;

		> * {
			flex: 1;
		}

		> :first-child {
			flex: 2;
		}
	}
}

.code {
	margin-bottom: $default_padding;

	pre {
		display: inline;
	}
}

table {
	max-width: none;
	table-layout: fixed;

	th + th,
	td + td {
		width: 72px;
		padding: 0;
		text-align: center;
	}

	label {
		margin: 0;
	}

	.global td {
		border-bottom: 2px solid rgba($color__dark, 0.2);
	}
}
